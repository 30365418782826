import React, { lazy, Suspense } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { CircularProgress } from "@mui/material";
import ScrollToTopNav from "./components/Utils/ScrollToTopNav";

// pages for this product
const LandingPage = lazy(() => import("views/LandingPage/LandingPage.js"));
const Page = lazy(() => import("views/Page/Page.js"));
const LoginPage = lazy(() => import("views/LoginPage/LoginPage.js"));
const AdminPage = lazy(() => import("views/AdminPage/AdminPage.js"));
//import ProfilePage from "views/ProfilePage/ProfilePage.js";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTopNav />
      <Suspense
        fallback={
          <div className={"loadingCenterVerticalHorizontalBackground"}>
            <CircularProgress
              color="primary"
              className={"loadingCenterVerticalHorizontal"}
            />
          </div>
        }
      >
        <Routes>
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            }
          />
          <Route path="/LogIn" element={<LoginPage />} />
          <Route path="/:id" element={<Page />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
