import { auth } from "../config/firebase";

let bearerToken = "";
// eslint-disable-next-line no-undef
const url = process.env.REACT_APP_BACKEND_FUNCTION_URL;
let headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  Authorization: "",
};

async function getIDToken() {
  let token;
  if (auth.currentUser) {
    await auth.currentUser.getIdToken().then(function (idToken) {
      token = idToken;
      bearerToken = "Bearer " + token;
      headers.Authorization = bearerToken;
    });
  }
}

/*
 * User-based Endpoints
 */

export async function getMyUser() {
  await getIDToken();
  try {
    let response = await fetch(url + "/users/me", {
      method: "GET",
      headers: headers,
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.error("Error fetching data:", json);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export async function getUsers() {
  await getIDToken();
  try {
    let response = await fetch(url + "/users", {
      method: "GET",
      headers: headers,
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.error("Error fetching data:", json);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export async function getUserByID(userID) {
  await getIDToken();
  try {
    let response = await fetch(url + "/users/" + userID, {
      method: "GET",
      headers: headers,
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.error("Error fetching data:", json);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export async function createUser(user) {
  await getIDToken();
  try {
    let response = await fetch(url + "/users", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(user),
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.error("Error fetching data:", json);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export async function updateUserByID(userID, user) {
  await getIDToken();
  try {
    let response = await fetch(url + "/users/" + userID, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(user),
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.error("Error fetching data:", json);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export async function updateMyUser(user) {
  await getIDToken();
  try {
    let response = await fetch(url + "/users/me", {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(user),
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.error("Error fetching data:", json);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

/*
Event-based endpoints
 */
export async function createEvent(event) {
  return new Promise((resolve, reject) => {
    event.description = btoa(
      encodeURIComponent((" " + event.description).slice(1)),
    );
    event.title = event.title.trim();
    event.instagramRequester = event.instagramRequester.trim();
    fetch(url + "/events", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(event),
    })
      .then((response) => {
        response.json().then((json) => {
          if (json.status === "successful") {
            resolve(json);
          } else {
            reject(json.message);
          }
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getEvents(event) {
  await getIDToken();
  return new Promise((resolve, reject) => {
    fetch(url + "/events", {
      method: "GET",
      headers: headers,
      body: JSON.stringify(event),
    })
      .then((response) => {
        response.json().then((json) => {
          resolve(json);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function acceptEvent(eventid) {
  await getIDToken();
  return new Promise((resolve, reject) => {
    fetch(url + "/events/accept/" + eventid, {
      method: "POST",
      headers: headers,
    })
      .then((response) => {
        response.json().then((json) => {
          if (json.status === "confirmed") {
            resolve(json);
          } else {
            reject(json.message);
          }
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function deleteEvent(eventid) {
  await getIDToken();
  return new Promise((resolve, reject) => {
    fetch(url + "/events/" + eventid, {
      method: "DELETE",
      headers: headers,
    })
      .then((response) => {
        response.json().then((json) => {
          resolve(json);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function updateEvent(eventid, event) {
  await getIDToken();
  event.description = btoa(
    encodeURIComponent((" " + event.description).slice(1)),
  );
  event.title = event.title.trim();
  event.instagramRequester = event.instagramRequester.trim();
  return new Promise((resolve, reject) => {
    fetch(url + "/events/" + eventid, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(event),
    })
      .then((response) => {
        response.json().then((json) => {
          resolve(json);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}
