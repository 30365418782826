import React, { createContext, useEffect, useMemo, useState } from "react";
import { CircularProgress, CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { deDE } from "@mui/material/locale";
import { useLazyQuery } from "@apollo/client";

import Themes_QUERY from "../components/Queries/Themes";

export const ThemeContext = createContext({
  loading: true,
});

// eslint-disable-next-line react/prop-types
function ThemeProvider({ children }) {
  const [themeOptions, setThemeOptions] = useState({
    typography: {
      fontSize: 14,
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      fontWeightLight: 200,
      fontWeightRegular: 300,
      fontWeightMedium: 400,
      fontWeightBold: 500,
    },
  });
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [getThemes, { loading: loadingTheme, data }] =
    useLazyQuery(Themes_QUERY);

  let theme = useMemo(() => createTheme(themeOptions), [themeOptions]);

  useEffect(() => {
    //setLoading(false);
    getThemes();
  }, []);

  useEffect(() => {
    if (data) {
      setThemeOptions(
        {
          palette: {
            type: data.themes[0].type,
            primary: {
              main: data.themes[0].primaryColor.hex,
            },
            secondary: {
              main: data.themes[0].secondaryColor.hex,
            },
            error: {
              main: data.themes[0].errorColor.hex,
            },
            warning: {
              main: data.themes[0].warningColor.hex,
            },
            info: {
              main: data.themes[0].infoColor.hex,
            },
            success: {
              main: data.themes[0].successColor.hex,
            },
            divider: "rgba(0,0,0,0.28)",
            footer: {
              background: data.themes[0].footerBackgroundColor.hex,
              color: data.themes[0].footerColor.hex,
            },
            header: {
              background: data.themes[0].headerBackgroundColor.hex,
              color: data.themes[0].headerColor.hex,
              transparentBackground: data.themes[0].headerTransparent,
            },
            headerOnScroll: {
              background: data.themes[0].headerOnScrollBackgroundColor.hex,
              color: data.themes[0].headerOnScrollColor.hex,
            },
            parallax: {
              background: data.themes[0].parallaxBackgroundColor.hex,
            },
          },
          typography: {
            fontSize: 14,
            color: data.themes[0].fontColor.hex,
            fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
            fontWeightLight: 200,
            fontWeightRegular: 300,
            fontWeightMedium: 400,
            fontWeightBold: 500,
            h1: {
              fontFamily: '"Roboto Slab", "Times New Roman", "serif"',
              fontSize: "3.0rem",
              fontWeight: 300,
              lineHeight: 1.25,
              [theme.breakpoints.down("md")]: {
                fontSize: "2.5rem",
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: "2.2rem",
              },
            },
            h2: {
              fontFamily: '"Roboto Slab", "Times New Roman", "serif"',
              fontSize: "2.5rem",
              fontWeight: 300,
              lineHeight: 1.25,
            },
            h3: {
              fontFamily: '"Roboto Slab", "Times New Roman", "serif"',
              fontSize: "2.0rem",
              fontWeight: 300,
              lineHeight: 1.25,
            },
            h4: {
              fontFamily: '"Roboto Slab", "Times New Roman", "serif"',
              fontSize: "1.5rem",
              fontWeight: 300,
              lineHeight: 1.25,
            },
            h5: {
              fontFamily: '"Roboto Slab", "Times New Roman", "serif"',
              fontSize: "1.2rem",
              fontWeight: 300,
              lineHeight: 1.3,
              [theme.breakpoints.down("sm")]: {
                fontSize: "1.1rem",
              },
            },
            h6: {
              fontFamily: '"Roboto Slab", "Times New Roman", "serif"',
              fontSize: "1.3rem",
              fontWeight: 400,
              lineHeight: 1.6,
            },
            subtitle1: {
              fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
              fontSize: "2rem",
              fontWeight: 300,
              lineHeight: 1.5,
              [theme.breakpoints.down("sm")]: {
                fontSize: "1.6rem",
              },
            },
            subtitle2: {
              fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
              fontSize: "1.1rem",
              fontWeight: 300,
              lineHeight: 1.5,
            },
          },
          components: {
            MuiCssBaseline: {
              styleOverrides: {
                root: {
                  fontSize: data.themes[0].fontSizeNew + "rem",
                },
                html: {
                  scrollBehavior: "smooth",
                },
                body: {
                  color: data.themes[0].fontColor.hex,
                  fontSize: data.themes[0].fontSizeNew + "rem",
                  lineHeight: data.themes[0].lineHeight,
                },
                h1: {
                  fontWeight: "300",
                  lineHeight: "1.5em",
                  color: data.themes[0].primaryColor.hex,
                },
                h2: {
                  fontWeight: "300",
                  lineHeight: "1.5em",
                  color: data.themes[0].primaryColor.hex,
                },
                h3: {
                  fontWeight: "300",
                  lineHeight: "1.5em",
                  color: data.themes[0].primaryColor.hex,
                },
                h4: {
                  fontWeight: "300",
                  lineHeight: "1.5em",
                  color: data.themes[0].primaryColor.hex,
                },
                h5: {
                  fontWeight: "300",
                  lineHeight: "1.5em",
                  color: data.themes[0].primaryColor.hex,
                },
                h6: {
                  fontWeight: "300",
                  lineHeight: "1.5em",
                  color: data.themes[0].primaryColor.hex,
                },
                //Override Style from Fullcalendar
                ".fc-scrollgrid-section-body": {
                  cursor: "pointer",
                },
                ".fc-button-primary": {
                  backgroundColor: theme.palette.primary.main + " !important",
                  border: "none !important",
                  color: "white !important",
                },
                ".fc-daygrid-event": {
                  overflow: "hidden",
                },
                ":root": {
                  "--fc-event-bg-color":
                    theme.palette.primary.main + " !important",
                  "--fc-event-border-color":
                    theme.palette.primary.main + " !important",
                },
                // override style from cookie banner
                ".osano-cm-button": {
                  borderColor: "transparent",
                },
              },
            },
          },
          props: {
            MuiAppBar: {
              color: "inherit",
            },
            MuiList: {
              dense: true,
            },
            MuiMenuItem: {
              dense: true,
            },
            MuiTable: {
              size: "small",
            },
            MuiTooltip: {
              arrow: true,
            },
          },
        },
        deDE,
      );

      setLoading(false);
    }
  }, [data]);

  const refreshTheme = () => {
    getThemes();
  };

  if (loading) {
    return (
      <div className={"loadingCenterVerticalHorizontalBackground"}>
        <CircularProgress
          color="primary"
          className={"loadingCenterVerticalHorizontal"}
        />
      </div>
    );
  }
  return (
    <ThemeContext.Provider
      value={{
        loading: loading,
        refreshTheme: refreshTheme,
      }}
    >
      <MUIThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {/* eslint-disable-next-line react/prop-types */}
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;
