import React from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../../providers/UserProvider";

function ProtectedRoute({ children }) {
  const location = useLocation();
  return (
    <UserContext.Consumer>
      {({ loading, user }) => (
        <>
          {!loading ? (
            user ? (
              children
            ) : (
              <Navigate
                to="/LogIn"
                replace
                state={{ path: location.pathname }}
              />
            )
          ) : (
            ""
          )}
        </>
      )}
    </UserContext.Consumer>
  );
}

ProtectedRoute.propTypes = {
  children: PropTypes.object.isRequired,
};

export default ProtectedRoute;
