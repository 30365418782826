import React from "react";
import ReactDOM from "react-dom";
import {
  InMemoryCache,
  ApolloProvider,
  ApolloClient,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import ReactGA from "react-ga4";

import App from "./App";
import UserProvider from "./providers/UserProvider";
import ThemeProvider from "./providers/ThemeProvider";

import "./assets/css/index.css";

const httpLink = createHttpLink({
  // eslint-disable-next-line no-undef
  uri: process.env.REACT_APP_BACKEND_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  // eslint-disable-next-line no-undef
  const token = process.env.REACT_APP_BACKEND_GRAPHQL_AUTH_TOKEN;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          launches: {
            keyArgs: false,
            merge(existing, incoming) {
              let launches = [];
              if (existing && existing.launches) {
                launches = launches.concat(existing.launches);
              }
              if (incoming && incoming.launches) {
                launches = launches.concat(incoming.launches);
              }
              return {
                ...incoming,
                launches,
              };
            },
          },
        },
      },
    },
  }),
});

// eslint-disable-next-line no-undef
process.env.REACT_APP_GOOGLE_ANALYTICS_MID !== undefined
  ? // eslint-disable-next-line no-undef
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MID)
  : "";

ReactDOM.render(
  <ApolloProvider client={client}>
    <UserProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </UserProvider>
  </ApolloProvider>,
  document.getElementById("root"),
);
