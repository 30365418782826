import React, { createContext, useEffect, useState } from "react";
import { auth, logout } from "../config/firebase";
import { getMyUser, updateMyUser } from "../api/RESTClient";

export const UserContext = createContext({
  user: null,
  token: null,
  loading: true,
});

// eslint-disable-next-line react/prop-types
function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      let user = null;
      if (userAuth != null) {
        user = await getMyUser();
      }
      setUser(user);
      setLoading(false);
      if (userAuth) {
        let authToken = "";
        userAuth.getIdToken().then(function (idToken) {
          authToken = idToken;
        });
        setToken(authToken);
      }
    });
  }, []);

  const updateUser = async () => {
    const user = await updateMyUser(user);
    setUser(user);
  };

  const getUser = async () => {
    const user = await getMyUser();
    setUser(user);
  };

  const logoutUser = () => {
    logout();
    setUser(null);
    setToken(null);
    setLoading(true);
  };

  return (
    <UserContext.Provider
      value={{
        loading: loading,
        user: user,
        updateUser: updateUser,
        token: token,
        logoutUser: logoutUser,
        getUser: getUser,
      }}
    >
      {/* eslint-disable-next-line react/prop-types */}
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
