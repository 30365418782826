/*eslint-disable no-undef*/
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_PROJECT_NAME + ".firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_NAME,
  storageBucket: process.env.REACT_APP_FIREBASE_PROJECT_NAME + ".appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const logInWithEmailAndPassword = (email, password) => {
  return new Promise(function (resolve, reject) {
    signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error signing in with password and email", error);
        reject(error);
      });
  });
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const logout = () => {
  signOut(auth)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log("Error with signOut: ", error);
      return false;
    });
};

export { auth, logInWithEmailAndPassword, sendPasswordReset, logout };
