import { gql } from "@apollo/client";

const Themes_QUERY = gql`
  query {
    themes {
      errorColor {
        hex
      }
      fontSize
      fontSizeNew
      lineHeight
      fontColor {
        hex
      }
      footerBackgroundColor {
        hex
      }
      footerColor {
        hex
      }
      headerBackgroundColor {
        hex
      }
      headerColor {
        hex
      }
      headerOnScrollBackgroundColor {
        hex
      }
      headerOnScrollColor {
        hex
      }
      headerTransparent
      parallaxBackgroundColor {
        hex
      }
      infoColor {
        hex
      }
      type
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      successColor {
        hex
      }
      warningColor {
        hex
      }
    }
  }
`;
export default Themes_QUERY;
